@import './variables';

$border-radius-46: 46px;
$border: 1px solid
  rgba(
    $color: #fff,
    $alpha: 0.2
  );
$iphoneSe: 376px;

.background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  object-fit: cover;
  height: 100%;
  top: 0;
  left: 0;
}

.layoutContainer {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 4;

  @media (max-width: $tabletSmallSize) {
    padding: 0 16px;
  }
}

.windowPlatter {
  border-radius: 46px;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 633px;

  @media (max-width: $tableSmall) {
    min-height: 100%;
  }

  @media (max-width: $tableSmall) {
    border-radius: 30px;
  }
}

.outletContent {
  position: relative;
  z-index: 3;
  height: 100%;
}

.blur {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: $border-radius-46;
  z-index: 1;
  background-color: #ffffff33;
  backdrop-filter: blur(50px);

  @media (max-width: $tableSmall) {
    border-radius: 30px;
  }
}

.footer {
  padding: 5% 0 20px 0;
  text-align: center;
  font-family: Londrina;
  font-size: 20px;
  color: #fff;
  line-height: 24px;
  letter-spacing: 0.67px;

  @media (max-width: $tabletSmallSize) {
    padding: 5% 0 20px 0;
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1280px !important;
  }
}

$tableBigSize: 1200px;
$tabletMediumSize: 991px;
$tabletSmallSize: 767px;
$tableSmall: 576px;
$iphoneSe: 376px;

$titleSize: 35px;
$titleSmallSize: 27px;
$titleIphoneSeSize: 1.5rem;
$smallPhone: 404px;

$sizeIcon: 44px;
$smallSizeIcon: 34px;

$border-radius: 24px;

@mixin font35Reponsive {
  font-family: Londrina;
  font-size: 35px;
  letter-spacing: 1.17px;
  line-height: 38px;

  @media (max-width: $tableBigSize) {
    font-size: 28px;
  }

  @media (max-width: $tabletSmallSize) {
    font-size: $titleIphoneSeSize;
    line-height: 2rem;
  }

  @media (max-width: $tableSmall) {
    font-size: 20px !important;
  }
}

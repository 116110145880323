@import './variables';

.aboutView {
  padding: 40px 42px;
  height: 100%;

  @media (max-width: $tableSmall) {
    padding: 30px 22px;
  }
}

.whoPounchy {
  max-width: 374px;
  max-height: 58px;
  object-fit: cover;
  @media (max-width: $tableBigSize) {
    max-width: 300px;
  }
  @media (max-width: $smallPhone) {
    max-width: 250px;
  }
}

.pounchyGif {
  max-width: 100%;
  transform: scale(1.4);
  object-fit: cover;
  margin-top: 55px;

  @media (max-width: $tabletMediumSize) {
    margin-top: 0px;
    max-height: 400px;
    max-width: 400px;
    transform: scale(1);
  }
}

.pounchyLogo {
  max-width: 219px;
  max-height: 62px;
  object-fit: cover;

  @media (max-width: $tableBigSize) {
    max-width: 150px;
  }
}

.aboutViewRight {
  padding-left: 60px;
  @media (max-width: $tabletMediumSize) {
    padding-left: 0px;
  }
}

.pounchyForEveryone {
  @include font35Reponsive;
  color: #fff;
  margin-top: 16px;
}

.aboutContent {
  font-family: Londrina;
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 38px;
  color: #fff;
  margin-top: 16px;
  @media (max-width: $tableBigSize) {
    font-size: $titleIphoneSeSize;
    line-height: 2rem;
  }
  @media (max-width: $iphoneSe) {
    font-size: 21px;
  }
}

@import './variables';

.homeViewContainer {
  font-family: Londrina;
  color: #fff;
  padding: 40px 42px;
  @media (max-width: $tableSmall) {
    padding: 30px 22px;
  }
}

.introducingText {
  font-size: $titleSize;
  letter-spacing: 1.17px;
  line-height: 38px;

  @media (max-width: $tabletSmallSize) {
    font-size: $titleSmallSize;
  }
}

.baseBounchyImage {
  max-width: 269px;
  max-height: 142px;
  object-fit: cover;
  margin-top: 8px;

  @media (max-width: $tabletSmallSize) {
    max-width: 200px;
    max-height: 100px;
  }

  @media (max-width: $iphoneSe) {
    max-width: 180px;
    max-height: 100px;
  }
}

.slogan {
  word-break: break-word;
  max-width: 742px;
  font-size: $titleSize;
  letter-spacing: 1.17px;
  line-height: 38px;
  margin-top: 50px;
  text-align: center;
  opacity: 0.8;
  @media (max-width: 884px) {
    font-size: 32px;
  }
  @media (max-width: $tabletSmallSize) {
    font-size: $titleIphoneSeSize;
    line-height: 2rem;
  }
}

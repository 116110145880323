@import './variables';

.button {
  border: none;
  border-radius: $border-radius;
  // border: $border;
  color: #fff;
  background-color: #ffffff33;
  transition: 0.2s all linear;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  padding: 10px 11px;
  font-family: SF-Pro;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  z-index: 2;
  user-select: none;
  height: 44px;
  max-height: 44px;
  &:hover {
    background: rgba(0, 0, 0, 0.08) !important;
  }

  &:disabled {
    cursor: default;
    &:hover {
      background-color: #ffffff33 !important;
    }
  }
}

.text {
  font-family: SF-Pro;
  font-size: 17px;
  font-weight: 500;
  position: relative;
  z-index: 3;
  line-height: 17px;

  @media (max-width: $tabletSmallSize) {
    font-size: 12px;
  }
}

.blur {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: $border-radius;
  z-index: 1;
  background-color: #ffffff33;
  backdrop-filter: blur(50px);
}

@import './variables';

.buttonIcon {
  border: none;
  border-radius: 50%;
  // border: 1px solid rgba($color: #fff, $alpha: 0.2);
  color: #fff;
  background-color: #ffffff33;
  transition: 0.2s all linear;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  font-family: SF-Pro;
  font-size: 17px;
  font-weight: 500;
  &:hover {
    background: rgba(0, 0, 0, 0.5) !important;
  }
  &:disabled {
    cursor: default;
    &:hover {
      background-color: #ffffff33 !important;
    }
  }
  position: relative;
  user-select: none;
  z-index: 2;
  width: $sizeIcon;
  height: $sizeIcon;
  max-width: $sizeIcon;
  max-height: $sizeIcon;

  @media (max-width: $tabletSmallSize) {
    width: $smallSizeIcon;
    height: $smallSizeIcon;
    max-width: $smallSizeIcon;
    max-height: $smallSizeIcon;
  }
}

.icon {
  position: relative;
  z-index: 3;
  line-height: 17px;
  color: #fff;

  @media (max-width: $tabletSmallSize) {
    svg {
      width: 16px;
    }
  }
}

.blur {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 24px;
  z-index: 1;
  background-color: #ffffff33;
  backdrop-filter: blur(50px);
}

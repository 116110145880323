.dotContainer {
  left: 50%;
  bottom: 25px;
  z-index: 4;

  .dotWrapper {
    height: 8px;
    width: 8px;
    background-color: rgba($color: #fff, $alpha: 0.2);
    transition: 0.2s all linear;
    border-radius: 4px;

    &.--active {
      width: 16px;
    }

    .dot {
      width: 8px;
      height: 8px;
      background-color: transparent;
      transition: 0.2s all linear;
      border-radius: 4px;

      &.--active {
        background-color: #fff;
      }
    }
  }
}

@import './variables';

.tokenomicsView {
  font-family: Londrina;
  color: #fff;
  padding: 40px 42px;

  @media (max-width: $tableSmall) {
    padding: 30px 22px;
  }
}

.tokenOmicsLogo {
  max-width: 312px;
  max-height: 58px;
  object-fit: cover;
  margin-bottom: 30px;
  @media (max-width: $tableBigSize) {
    max-height: 47px;
  }
}

.chart {
  width: 100%;
  height: auto;
  max-width: 420px;
  max-height: 385px;
  object-fit: cover;
}

.contactRenounced,
.locked {
  padding: 2px 14px;
  @media (max-width: $tableSmall) {
    height: 35px !important;
  }
}

.locked {
  background-color: rgba($color: #000, $alpha: 0.3);
  &:disabled {
    &:hover {
      background-color: rgba($color: #000, $alpha: 0.3) !important;
    }
  }
}

.contactRenouncedText,
.lockedText {
  font-family: Londrina !important;
  font-size: 20px !important;
  letter-spacing: 0.67px !important;
  line-height: 38px !important;
  font-weight: 400;

  @media (max-width: $tableSmall) {
    font-size: 13px !important;
    line-height: 22px !important;
  }
}

.tokenomicsViewRight {
}

.contactAddressText {
  @include font35Reponsive;
  color: rgba($color: #fff, $alpha: 0.7);
  margin-bottom: 10px;
}

.addressValue {
  font-family: Londrina;
  font-size: 20px;
  letter-spacing: 0.67px;
  line-height: 38px;

  @media (max-width: $tabletSmallSize) {
    font-size: 18px;
  }

  @media (max-width: $tableSmall) {
    font-size: 16px;
  }
}

.copyButton {
  padding: 2px 24px;
  width: 100%;
  max-width: 467px;
}

.tokenomicsContent {
  @include font35Reponsive;
  @media (max-width: 1400px) {
    font-size: 34px;
  }
}

.contractLabel {
  margin-bottom: 25px;
  @media (max-width: $tabletSmallSize) {
    margin-bottom: 10px;
  }
}

@import './font.scss';
@import './variables.scss';
@import './dotPagination.scss';

body {
  font-family: SF-Pro;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #000;
  user-select: none;
}

#root {
  height: var(--app-height);
  background-color: #000;
  user-select: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.truncate-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:root {
  --break-point-md: 767px;
}

.cursor-pointer {
  cursor: pointer;
}

img {
  pointer-events: none;
}

.hidden-scroll-bar {
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hidden {
  visibility: hidden;
}

a {
  text-decoration: none !important;
}

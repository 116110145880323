@import './variables';

.header {
  padding: 20px 20px 3% 20px;
  @media (max-width: $tabletSmallSize) {
    padding: 10px;
  }
}

.logo {
  width: 100%;
  max-width: 219px;
  height: auto;
  max-height: 62px;
  object-fit: cover;
  position: relative;
  z-index: 3;
  cursor: pointer;

  @media (max-width: $tabletSmallSize) {
    min-height: 56px;
  }

  @media (max-width: $tableSmall) {
    max-height: 50px;
  }

  @media (max-width: $iphoneSe) {
    max-width: 200px;
    max-height: 100px;
  }
}

.swapOnSushiButton {
  padding: 10px 16px;
  height: 56px;
  max-height: 56px;
  border-radius: 28px !important;

  @media (max-width: $tabletSmallSize) {
    padding: 5px 13px;
  }
}

.swapOnSushiTitle {
  font-family: Londrina;
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  @media (max-width: $tabletSmallSize) {
    font-size: 16px;
    svg {
      width: 16px;
    }
  }
}

.ethereumIcon {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

@import './variables';

.notiWrapper {
  left: 24px;

  @media (max-width: $tabletSmallSize) {
    left: 12px;
  }
}

.blur {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 24px;
  z-index: -1;
  background-color: rgba($color: #fff, $alpha: 0.02);
  backdrop-filter: blur(50px);
}

.noti {
  border-radius: 50px;
  position: relative;
  z-index: 4;
  width: fit-content;
  padding: 5px 6px 5px 16px;
  color: #fff;

  @media (max-width: 600px) {
    left: -14px;
  }

  @media (max-width: $iphoneSe) {
    font-size: 11px;
  }

  svg {
    width: 12px;
  }
}

.notiButton {
  padding: 11px 21px;
}
